import { createStore } from 'vuex'
import DerechosPeticionService from '../services/derechosPeticion.service'

const _derechosPeticionService = new DerechosPeticionService()
const storeDerechosPeticion = {
  state: {
    _derechosPeticion: [],
    _adjunto: {},
    derecho: {
      persona: {
        id: null,
        fullname: null,
        numeroIdentificacion: null,
        docTipoId: null,
        telefono: null,
        correo: null,
        departamentoId: null,
        municipioId: null,
        representanteLegal: null,
        direccion: null
      },
      personaId: null,
      asunto: null,
      fechaNotificacion: null,
      fechaVencimiento: null,
      tipo: null,
      accionante: {
        representanteLegal: null
      },
      respuestas: []
    },
    derechoShow: {
      persona: {
        fullname: null,
        numeroIdentificacion: null,
        docTipoId: null,
        telefono: null,
        correo: null,
        departamentoId: null,
        municipioId: null,
        representanteLegal: null,
        direccion: null
      },
      asunto: null,
      fechaNotificacion: null,
      fechaVencimiento: null
    }
  },
  getters: {
    derechosPeticion: state => state._derechosPeticion,
    adjunto: state => state._adjunto,
    _derecho: state => state.derecho,
    _derechoShow: state => state.derechoShow
  },
  mutations: {
    setDerechos (state, payload) {
      state._derechosPeticion = payload
    },
    setDerecho (state, payload) {
      state.derecho = payload
    },
    setDerechoShow (state, payload) {
      state.derechoShow = payload
    },
    setAdjunto (state, payload) {
      state._adjunto = {
        ...payload
      }
    },
    setPersona (state, payload) {
      state.derecho.personaId = payload.id ?? null
      state.derecho.persona = {
        id: payload.id ?? null,
        fullname: payload.fullname,
        numeroIdentificacion: payload.numeroIdentificacion,
        docTipoId: payload.docTipoId,
        telefono: payload.telefono,
        correo: payload.correo,
        departamentoId: payload.departamentoId,
        municipioId: payload.municipioId,
        representanteLegal: payload.representanteLegal,
        direccion: payload.direccion
      }
    },
    updatePersona (state, payload) {
      state.derecho.persona = {
        fullname: payload.fullname,
        numeroIdentificacion: payload.numeroIdentificacion,
        docTipoId: payload.docTipoId,
        telefono: payload.telefono,
        correo: payload.correo,
        departamentoId: payload.departamentoId,
        municipioId: payload.municipioId,
        representanteLegal: payload.representanteLegal,
        direccion: payload.direccion
      }
    },
    setDetalleDerecho (state, payload) {
      state.derecho.fechaNotificacion = payload.fechaNotificacion
      state.derecho.fechaVencimiento = payload.fechaVencimiento
      state.derecho.asunto = payload.asunto
      state.derecho.paths = payload.paths
      state.derecho.tipo = payload.tipo
    },
    setReset (state) {
      state.derecho = {
        persona: {
          id: null,
          fullname: null,
          numeroIdentificacion: null,
          docTipoId: null,
          telefono: null,
          correo: null,
          departamentoId: null,
          municipioId: null,
          representanteLegal: null,
          direccion: null
        },
        personaId: null,
        asunto: null,
        fechaNotificacion: null,
        fechaVencimiento: null,
        tipo: null
      }
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      return _derechosPeticionService.paginate(payload).then(({ data }) => {
        commit('setDerechos', data)
        return data.rows
      })
    },
    createDerecho ({ getters }) {
      console.log('getter-->', getters._derecho)
        return _derechosPeticionService.save(getters._derecho).then((response) => {
            return response
        })
    },
    findDerecho ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _derechosPeticionService.find(id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    find ({ commit }, payload) {
      _derechosPeticionService.find(payload).then(({ data }) => {
        commit('setDerecho', data)
      })
    },
    show ({ commit }, payload) {
      _derechosPeticionService.find(payload).then(({ data }) => {
        commit('setDerechoShow', data)
      })
    },
    updateDerecho ({ getters }, id, payload) {
      return new Promise((resolve, reject) => {
        _derechosPeticionService.edit(getters._derecho, id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    removeDerecho ({ commit }, id) {
      return _derechosPeticionService.remove(id).then((response) => {
        return response
      })
    },
    uploadFile ({ commit }, payload) {
      commit('setAdjunto', payload)
    }
  }
}
const store = createStore(storeDerechosPeticion)
export default {
  namespaced: true,
  ...store
}
