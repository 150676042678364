<template>
 <div class="bg-white rounded-md shadow-sm p-6 w-full">
    <div class="mb-2">
        <p  class="font-bold text-base">
        {{ accionante ? accionante.fullName:  '' }}
        </p>
    </div>
    <div class="mt-2">
        <div class="flex justify-between my-3">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path class="" stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <p class="text-sm text-gray-500 font-medium">
            Email
            </p>
        </div>
        <div class="flex justify-end">
            <p class="text-sm text-black font-medium">
            {{ accionante ? accionante.correo:  '' }}
            </p>
        </div>
        </div>
        <div class="flex justify-between my-3">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
            </svg>
            <p class="text-sm text-gray-500 font-medium">
            Identificación
            </p>
        </div>
        <div class="flex justify-end">
            <p class="text-sm text-black font-medium">
              {{ accionante ? accionante.docTipoId:  '' }}{{ accionante ? accionante.numeroIdentificacion:  '' }}
            </p>
        </div>
        </div>
        <div class="flex justify-between my-3">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <p class="text-sm text-gray-500 font-medium">
            Teléfono
            </p>
        </div>
        <div class="flex justify-end">
            <p class="text-sm text-black font-medium">
              {{ accionante ? accionante.telefono:  '' }}
            </p>
        </div>
        </div>
        <div class="flex justify-between my-3">
        <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <p class="text-sm text-gray-500 font-medium">
                Localización
            </p>
        </div>
        <div class="flex justify-end">
            <p class="text-sm text-black font-medium">
              {{ derecho.localizacion }}
            </p>
        </div>
        </div>
        <div class="flex justify-between mt-2">
          <div class="flex items-center">
            <i class="pi pi-home text-blue-600 ml-1 mr-2 text-xs"></i>
            <span class="text-sm text-gray-500 font-medium">Dirección</span>
          </div>
          <span class="font-bold">{{ accionante ? accionante.direccion: '' }}</span>
        </div>
        <div v-if="accionante.representanteLegal" class="flex justify-between mt-2">
          <div class="flex items-center">
            <i class="pi pi-users text-blue-600 ml-1 mr-2 text-xs"></i>
            <span class="text-sm text-gray-500 font-medium">Representante Legal</span>
          </div>
          <span class="font-bold">{{ accionante ? accionante.representanteLegal: '' }}</span>
        </div>
    </div>
</div>
</template>
<script>
import derechosPeticionStore from '../../../../../../../store/derechosPeticion.store'
import { computed } from 'vue'

export default {
  name: 'personaDerecho',
  setup () {
    const derecho = computed(() => {
      return derechosPeticionStore.getters._derecho
    })
    const accionante = computed(() => {
      return derechosPeticionStore.getters._derecho.accionante
    })
    return {
      derecho, accionante
    }
  }
}
</script>
<style scoped>

</style>
